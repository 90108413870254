import { version, name } from "../package.json";
import defaultBg from "./assets/pexels-suzy-hazelwood-1629236.jpg";

import {
    RadioWidget,
    LayoutField,
    CustomDescription,
    CustomTitle,
    TypeaheadWidget,
    FileWidget,
    UpDownWidget,
    CalculatedWidget,
    TextareaWidget
} from "./Formulationist/Widgets/";
import { capFirst } from "./lib/utils";

export const appTitle = capFirst(name);
export { defaultBg };
export const globalWidgets = {
    FileWidget,
    RadioWidget,
    UpDownWidget,
    TextareaWidget,
    typeahead: TypeaheadWidget,
    calculated: CalculatedWidget
};
export const globalFields = { layout: LayoutField, TitleField: CustomTitle, DescriptionField: CustomDescription };
export const globalSchema = { correlationId: { type: "string" } };
export const globalUiSchema = {
    title: {
        "ui:widget": "title"
    },
    correlationId: { "ui:widget": "hidden" }
};
export const unwantedErrors = ["const", "oneOf"];
export const replaceErrors = {
    required: "You must fill in this field",
    format: "Please use the appropriate format",
    pattern: "Your answer doesn't conform to the expected pattern"
};

export const dfltSuccess = {
        title: "Form posted OK",
        message: "Your form was submitted and should be processed soon.",
        redirectUrl: undefined,
        target: "self",
        id: "success"
    },
    dfltFailure = {
        title: "Something went wrong",
        message: "Your form couldn't be submitted.",
        redirectUrl: undefined,
        target: "self",
        id: "failure"
    };
export { version };

export const defaultForm = "FormulationistIntroduction";
export const SERVER_CONFIG_KEY = "serverConfig";
