import axios from "axios";
import { useEffect, useState } from "react";

const getFormSchema = formBlobName => {
    const blobUrl = `/api/GetForm?form=${formBlobName}`;
    console.log("Loading form", blobUrl);
    return axios.get(blobUrl, { headers: { "Access-Control-Expose-Headers": "error" } });
};

export const useFormSchema = formBlobName => {
    const [formDef, setFormDef] = useState({});
    const [error, setError] = useState();
    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        setFetching(true);
        getFormSchema(formBlobName)
            .then(({ data }) => setFormDef(data))
            .catch(setError)
            .finally(() => setFetching(false));
    }, [formBlobName]);

    return { isFetching, error, data: formDef };
};
