import { Message } from "./Message";

const Banner = ({ spec }) => {
    const { title, message, severity, image } = spec;
    return (
        <Message title={title} content={message} severity={severity} image={image} compact={false} className="banner" />
    );
};

export default Banner;
