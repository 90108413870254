import Form from "@rjsf/bootstrap-4";
import {
    globalSchema,
    globalUiSchema,
    globalWidgets,
    globalFields,
    appTitle,
    unwantedErrors,
    replaceErrors
} from "../config";
import { AppContainer } from "../Page/AppContainer";
import useDocumentTitle from "@tanem/use-document-title";
import { Button, FormControl, FormGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { isObject, isEmptyObject, MS_PER_SECOND } from "../lib/utils";
import Banner from "../components/Banner";
import { ErrorBoundary } from "react-error-boundary";
import { FormSchemaError } from "./FormSchemaError";
import { useDelayedAction } from "../hooks/useDelayedAction";
import { useCallback, useState } from "react";
import { DirtyIndicator } from "./DirtyIndicator";
import { ReportResult } from "./ReportResult";
import { FaInfoCircle } from "react-icons/fa";
import { Message } from "../components/Message";
import { useToggle } from "../hooks/useToggle";
import { useUserInfo } from "@aaronpowell/react-static-web-apps-auth";

const isBanner = banner => banner && isObject(banner) && (banner.title || banner.message);

const noUnwantedErrors = e => !unwantedErrors.includes(e.name);
const transformErrors = errors =>
    errors.filter(noUnwantedErrors).map(e => (replaceErrors[e.name] ? { ...e, message: replaceErrors[e.name] } : e));

export const FormulationistCore = ({
    form,
    formData,
    lookupData,
    formSchema,
    sendFormData,
    storeFormData,
    resetFormData,
    revertFormData,
    setFormData,
    formPostResult,
    success,
    failure,
    editLink,
    editingForm
}) => {
    const {
        schema = {},
        uiSchema = {},
        widgets = {},
        maintainer = {},
        fields,
        title,
        description,
        version = "",
        hideDirtyIndicator,
        headerBg,
        topBanner = {},
        bottomBanner = {},
        hideButtons = [],
        buttonLabels = {},
        noEditLink = false
    } = formSchema;
    const docTitle = `${title} (${editingForm ? "editing" : "new"})`;
    useDocumentTitle(`${title ? docTitle + " - " : ""}${appTitle}`);
    const [isDirty, saveAfterDelay, resetDirty] = useDelayedAction(storeFormData, 5 * MS_PER_SECOND);

    const user = useUserInfo();
    const [currentField, setCurrentField] = useState({});
    const [underConstruction, toggleUnderConstruction] = useToggle(false);

    const saveFormDataWithDelay = useCallback(
        ({ formData }) => {
            setFormData(formData);
            saveAfterDelay({ formData });
        },
        [saveAfterDelay, setFormData]
    );

    const gotSchema = !isEmptyObject(schema);

    const resetFormDataAndDirtyFlag = () => {
        resetFormData();
        resetDirty();
    };
    const showDirtyIndicator = !hideDirtyIndicator;

    const getSchemaFromRef = ref => {
        const paths = ref.split("/");
        console.log("paths", paths);
        return paths.reduce(
            (a, part) => (part === "#" ? a : a[part].properties ? a[part].properties : a[part]),
            schema
        );
    };

    const getSchemaFromElementId = id => {
        console.log("id", id.split("_"));
        return id && typeof id === "string"
            ? id.split("_").reduce(
                  (a, id) =>
                      id === "root"
                          ? a.properties
                          : a[id]
                          ? a[id].properties
                              ? a[id].properties
                              : a[id].$ref
                              ? getSchemaFromRef(a[id].$ref)
                              : a[id]
                          : {},

                  schema
              )
            : {};
    };

    const handleFieldPropertyChange = e => {
        const control = e.target;
        const prop = control.getAttribute("data-property"),
            value = control.value;
        console.log(currentField[prop], prop, value);
        currentField[prop] = value;
        console.log(schema);
    };

    const FooterButton = props => <Button {...props}>{props.text}</Button>;

    const ButtonsInfoPanel = props => (
        <Popover className="buttonsTooltip" {...props}>
            <Popover.Title as="h4">The Action Buttons</Popover.Title>
            <Popover.Content>
                <ul>
                    <li>
                        <strong>{buttonLabels.submit || "Submit"}</strong> validates &amp; forwards your form.
                    </li>
                    {!hideButtons.includes("revert") && (
                        <li>
                            <strong>{buttonLabels.revert || "Revert"}</strong> returns the form to its starting point.
                        </li>
                    )}
                    {!hideButtons.includes("reset") && (
                        <li>
                            <strong>{buttonLabels.reset || "Reset"}</strong> clears everything so you can start again.
                        </li>
                    )}
                </ul>
            </Popover.Content>
        </Popover>
    );

    const ButtonsInfoPanelTrigger = () =>
        hideButtons.length >= 2 ? null : (
            <OverlayTrigger trigger="click" placement="top" overlay={ButtonsInfoPanel}>
                <FaInfoCircle color="#00f" className="trigger" />
            </OverlayTrigger>
        );

    const FormButtons = ({ revert, reset }) => (
        <>
            <FooterButton variant="primary" type="submit" text={buttonLabels.submit || "Submit"} />
            {!hideButtons.includes("revert") && (
                <FooterButton onClick={revert} variant="warning" text={buttonLabels.revert || "Revert"} />
            )}
            {!hideButtons.includes("reset") && (
                <FooterButton onClick={reset} variant="danger" text={buttonLabels.reset || "Reset"} />
            )}
        </>
    );

    const FormFooter = () => (
        <footer id="formFooter">
            {isBanner(bottomBanner) && <Banner spec={bottomBanner} />}{" "}
            <section className={"buttons" + (hideButtons.length > 0 ? " compact" : "")}>
                <FormButtons revert={revertFormData} reset={resetFormDataAndDirtyFlag} />
                <ButtonsInfoPanelTrigger />
                {user.identityProvider && user.userRoles.includes("constructor") && (
                    <ConstructionModeOnOff value={underConstruction} />
                )}
            </section>
        </footer>
    );

    const FieldProperty = ({ property, placeholder = "Field " + property, as, rows }) => (
        <FormControl
            data-property={property}
            value={currentField[property]}
            onChange={handleFieldPropertyChange}
            placeholder={placeholder}
        />
    );

    const ConstructionModeOnOff = ({ value }) => (
        <input
            type="checkbox"
            checked={value}
            onChange={toggleUnderConstruction}
            style={{ position: "fixed", top: 0, right: 0, zIndex: 5 }}
        />
    );

    const ConstructorPanel = () =>
        underConstruction ? (
            <aside id="constructor">
                <div className="inner" style={{ position: "fixed" }}>
                    <h4>Construction Zone</h4>
                    {currentField ? (
                        <>
                            <FormGroup>
                                <FieldProperty property="title" />
                                <FormControl
                                    value={currentField.description}
                                    placeholder="Field description"
                                    as="textarea"
                                    rows={3}
                                />
                                <FormControl value={currentField.type} as="select">
                                    <option>string</option>
                                    <option>number</option>
                                    <option>boolean</option>
                                </FormControl>
                                {currentField.enum && (
                                    <FormControl
                                        value={currentField.enum}
                                        placeholder="Possible Values"
                                        as="textarea"
                                        rows={3}
                                    />
                                )}
                            </FormGroup>
                            <textarea style={{ width: "100%", height: "10em" }} value={JSON.stringify(currentField)} />
                        </>
                    ) : (
                        <Message
                            title="Select a field"
                            content="Select a field to view and edit its properties"
                            severity="info"
                        />
                    )}
                </div>
            </aside>
        ) : null;

    return (
        <AppContainer
            title={title}
            description={description}
            headerBg={headerBg}
            additionalText={version && `Form version ${version}${editingForm ? "+" : ""}`}
            fullWidth={underConstruction}
        >
            {!formPostResult && showDirtyIndicator && <DirtyIndicator isDirty={isDirty} />}
            {gotSchema && (
                <ErrorBoundary
                    fallbackRender={({ error }) => (
                        <FormSchemaError
                            error={error}
                            maintainer={maintainer}
                            form={{ form, version, title, description }}
                        />
                    )}
                >
                    {formPostResult ? (
                        <ReportResult
                            result={formPostResult}
                            success={success}
                            failure={failure}
                            editLink={editLink}
                            noEditLink={noEditLink}
                        />
                    ) : (
                        <>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" }}>
                                    {isBanner(topBanner) && <Banner spec={topBanner} />}
                                    <Form
                                        noHtml5Validate
                                        showErrorList={false}
                                        schema={{ properties: { ...globalSchema }, ...schema }}
                                        formData={formData}
                                        formContext={{ formData, lookupData }}
                                        uiSchema={{ ...globalUiSchema, ...uiSchema, classNames: form }}
                                        widgets={{ ...globalWidgets, ...widgets }}
                                        fields={{ ...globalFields, ...fields }}
                                        children={<FormFooter />}
                                        onChange={saveFormDataWithDelay}
                                        onSubmit={sendFormData}
                                        onError={e => console.log("errors", e)}
                                        transformErrors={transformErrors}
                                        onFocus={id => setCurrentField(getSchemaFromElementId(id))}
                                        onBlur={null}
                                    />
                                </div>
                                <ConstructorPanel />
                            </div>
                        </>
                    )}
                </ErrorBoundary>
            )}
        </AppContainer>
    );
};
